.calc{
  &__title{
    font-size: 62px;
    line-height: 1.1;
    font-weight: 300;
    margin-bottom: 10px;
    text-align: center;
  }
  &__desc{
    line-height: 1.3;
    color: #737373;
    margin-bottom: 55px;
    text-align: center;
    margin-top: 0;
  }
  &__currVal{
    text-align: center;
    font-size: 28px;
    line-height: 1.3;
    color: #4c4c4c;
    font-weight: 300;
    margin-bottom: 16px;
    margin-top: 0;
  }
  &__wrapper{
    text-align: center;
    max-width: 384px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  &__range{
    height: auto;
    &-text{
      font-size: 16px;
      line-height: 1.3;
      font-weight: initial;
      color: #767676;
      margin-bottom: 14px;
    }
  }
  &__loading{
    margin-top: 30px;
    line-height: 1.3;
    color: #737373;
    margin-bottom: 55px;
    text-align: center;
  }
  &__promoCode{
    width: 100%;
    border: none;
    outline: none;
    border: none;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: lighter;
    background-color: inherit;
    padding-bottom: 3px;
  }
  &__payment{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 1.3;
    font-weight: initial;
    color: #767676;
    margin-bottom: 14px;
  }
  &__choose{
    &-text{
      font-size: 18px;
      line-height: 1.3;
      font-weight: initial;
      color: #767676;
      margin-bottom: 14px;
      margin-top: 16px;
      display: block;
    }
  }
  &__input{
    font-size: 30px;
    width: 150px;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #b3b3b3;
    margin-bottom: 0;
    -moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    &-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      align-items: center;
      width: max-content;
      margin: 0 auto;
    }
    &-symbol{
      font-size: 30px;
    }
    &--period{
      padding-right: 86px;
    }
    &--sum{
      padding-right: 40px;
    }
    &-label{
      font-weight: 400;
      font-size: 30px;
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.rc-slider{
  height: 56px;
  padding: 26px 0;
  .rc-slider-track{
    background-color: #308eda;
  }
  .rc-slider-handle{
    background-color: #308eda;
    border-color: #308eda;
  }
}
@media (min-width: 768px) {
  .calc{
    &__promoCode{
      margin-bottom: 29px;
    }
  }
}

.rc-slider-dot{
  display: none;
}

